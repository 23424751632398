<div class="row row--container mar-top-large">
  <div class="col sm-12">
    <div class="card mar-top-large">
      <div class="card__content">
        <div class="row">
          <h5>{{ 'CONTACTPERSONS.HEADER' | translate }}</h5>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row row--container">
  <div class="col sm-12">
    <div class="card mar-top-large">
      <div class="card__content">
        <p>
          <b>{{ 'CONTACTPERSONS.SUBHEADER_1' | translate }}</b>
        </p>

        <br />

        <p>
          {{ 'CONTACTPERSONS.AREAS_TEXT' | translate }}
        </p>

        <br />
        <p>
          <b>{{ 'CONTACTPERSONS.SUBHEADER_2' | translate }}</b>
        </p>
        <br />

        <p>
          {{ 'CONTACTPERSONS.WHO_TO_QUESTION' | translate }}
        </p>
        <br />
        <p>
          <a href="mailto:Robin.hoppe@volkswagen.de">Robin.hoppe&#64;volkswagen.de</a>
        </p>

        <p>
          <a href="mailto:Martin.pitzek@volkswagen.de">Martin.pitzek&#64;volkswagen.de</a>
        </p>

        <p>
          <a href="mailto:Christopher.towara@volkswagen.de">Christopher.towara&#64;volkswagen.de</a>
        </p>

        <br />
      </div>
    </div>
  </div>
</div>
