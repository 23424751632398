<div class="dashboard-component" *ngIf="rolesService.checkIfUserHasAnyOfPermissions(ALL_ROLES); else elseBlock">
  <div class="row row--container mar-top-large">
    <div class="col sm-12">
      <div class="card summary-card">
        <div class="card__content">
          <h5 class="left" name="dienste-tag">
            {{ 'DASHBOARD.SERVICES' | translate }}
          </h5>
          <div class="tag tag--secondary mar-left-large" name="service-number">
            {{ serviceNumber }}
          </div>
          <div class="right">
            <div class="btn-group btn-group--primary">
              <button
                class="btn"
                type="submit"
                [ngClass]="{ 'btn--active': isActive(ServiceView.CORE) }"
                (click)="onTabChange(ServiceView.CORE)"
                name="core-services"
              >
                {{ 'DASHBOARD.CORE_SERVICES' | translate }}
              </button>
              <button
                class="btn"
                type="submit"
                [ngClass]="{ 'btn--active': isActive(ServiceView.CUSTOMER) }"
                (click)="onTabChange(ServiceView.CUSTOMER)"
                name="customer-services"
              >
                {{ 'DASHBOARD.CUSTOMER_SERVICES' | translate }}
              </button>
              <button
                class="btn"
                type="submit"
                [ngClass]="{ 'btn--active': isActive(ServiceView.CUSTOMIZED) }"
                (click)="onTabChange(ServiceView.CUSTOMIZED)"
                name="customized-services"
              >
                {{ 'DASHBOARD.CUSTOMIZED_SERVICES' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-core-services
    *ngIf="isActive(ServiceView.CORE)"
    (numberChanged)="onServiceNumberChanged($event)"
  ></app-core-services>
  <app-customer-services
    *ngIf="isActive(ServiceView.CUSTOMER)"
    (numberChanged)="onServiceNumberChanged($event)"
  ></app-customer-services>
  <app-customized-services
    *ngIf="isActive(ServiceView.CUSTOMIZED)"
    (numberChanged)="onServiceNumberChanged($event)"
  ></app-customized-services>
</div>
<ng-template #elseBlock>
  <app-unauthorized-access></app-unauthorized-access>
</ng-template>
