import { Component, OnInit } from '@angular/core';
import { RolesService } from '../../shared/services/roles-service.service';
import { Role } from '../../shared/constants';
import { env } from '../../environment';
import { NgForm } from '@angular/forms';
import { RestrictedWordsService } from '../../shared/services/restricted-words.service';
import { Brand, RestrictedWord } from "../../shared/interfaces/restricted-word.interface";

@Component({
  selector: 'app-restrictedwords',
  templateUrl: './restricted-words.component.html',
  styleUrls: ['./restricted-words.component.scss'],
})
export class RestrictedWordComponent implements OnInit {
  dateTimeFormat: string;
  readonly ADMIN_ROLE: string = Role.ADMIN;
  public initialWords: RestrictedWord[] = [];
  public initialBrand: Brand = Brand.VWAG;

  constructor(public restrictedWordsService: RestrictedWordsService, public rolesService: RolesService) {
    this.dateTimeFormat = env.dateTimeFormat;
  }

  ngOnInit() {
    this.getRestrictedWords();
  }

  getRestrictedWords() {
    this.restrictedWordsService.getRestrictedWordsForBrand(this.initialBrand).subscribe({
      next: (words: RestrictedWord[]) => {
        this.initialWords = words;
      },
      error: (err: any) => {
        console.error('Failed to load restricted words', err);
      }
    });
  }

  addNewRestrictedWord(myForm: NgForm) {
    const wordToAdd = myForm.controls.word.value;
    if (this.isWordAlreadyAdded(wordToAdd)) {
      alert(`The word "${wordToAdd}" already exists.`);
      return;
    }

    this.restrictedWordsService.addNewRestrictedWord(this.initialBrand, wordToAdd).subscribe((data) => {
      this.initialWords.push({ word: wordToAdd });
      myForm.resetForm();
    });
  }

  deleteRestrictedWord(word: RestrictedWord) {
    this.restrictedWordsService.deleteRestrictedWord(this.initialBrand, word)
      .subscribe(() => this.initialWords = this.initialWords.filter(w => w !== word));
  }

  private isWordAlreadyAdded(word: string): boolean {
    return this.initialWords.some(existingWord =>
      existingWord.word.toLowerCase() === word.toLowerCase()
    );
  }

  handleBrandChange(brand: Brand) {
    this.initialBrand = brand;
    this.getRestrictedWords();
  }
}
