export interface RestrictedWord {
    word: string;
}

export enum Brand {
    VWAG = 'VWAG',
    SEAT = 'SEAT',
    TEST = 'TEST',
    PRODUCTION = "PRODUCTION"
  }
