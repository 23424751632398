import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants';
import {RestrictedWord} from "../interfaces/restricted-word.interface";

@Injectable({
  providedIn: 'root'
})
export class RestrictedWordsService {
  constructor(private http: HttpClient) {}

  getRestrictedWordsForBrand(brand: string) {
    return this.http.get(Constants.restrictedWordsUri(brand), {
      responseType: 'json'
    });
  }

  addNewRestrictedWord(brand: string, word: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    if (word.trim() !== '') {
      return this.http.post(Constants.restrictedWordsUri(brand), JSON.stringify({ word }), httpOptions);
    }
  }

  deleteRestrictedWord(brand: string, restrictedWord: RestrictedWord) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(restrictedWord)
    };
    if (restrictedWord.word.trim() !== '') {
      return this.http.delete(Constants.restrictedWordsUri(brand), httpOptions);
    }
  }
}
