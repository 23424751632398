import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Localization } from 'src/app/shared/interfaces/failure.interface';
import { CustomizedService, Generation } from '../../../../shared/interfaces/service.interface';
import {
  CoreServiceClient,
  CustomerServiceClient,
  CustomizedServiceClient
} from '../../../../shared/services/services.service';
import { ServiceEditBaseDirective } from '../../service-edit-base.directive';

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-customized-service-edit',
  templateUrl: './customized-service-edit.component.html',
  styleUrls: ['./customized-service-edit.component.scss']
})
export class CustomizedServiceEditComponent extends ServiceEditBaseDirective<CustomizedService> implements OnInit {
  public formFilters = {
    type: undefined,
    nodes: []
  };
  public FILTER_KEY = 'filter';

  public readonly SERVICE_SUCCESS_MESSAGE = 'successMessages';
  public readonly SERVICE_FAILURE_MESSAGE = 'failureMessages';
  public readonly SERVICE_SUCCESS_AND_FAILURE_MESSAGE = 'successAndFailureMessages';
  public readonly SERVICE_MISSING_EVENTS_MESSAGE = 'missingEventMessages';
  public readonly SERVICE_INFO_MESSAGE = 'infoMessages';

  @Input() servicesProvider: () => Map<string, Map<Generation, CustomizedService[]>>;

  brands() {
    return Array.from(this.servicesProvider().keys());
  }

  constructor(
    private coreServiceClient: CoreServiceClient,
    private customerServiceClient: CustomerServiceClient,
    private customizedServiceClient: CustomizedServiceClient
  ) {
    super(coreServiceClient, customerServiceClient);
  }

  public stringOperators = [
    {
      value: 'string_eq',
      displayName: 'String Equals'
    },
    {
      value: 'string_query',
      displayName: 'String Query'
    }
  ];

  public accordionTabActive: number;

  public generateFilterId = (): string => uuidv4();

public handleInputChange = (event: Event & { target: HTMLInputElement }, id: string, key: string) => {
    const formFiltersCopy = this.formFilters;

    const editedFilterIndex = formFiltersCopy.nodes.findIndex((node) => node.id === id);

    formFiltersCopy.nodes[editedFilterIndex][key] = event.target.value;
    this.formFilters = formFiltersCopy;
  };

  handleSelectChange = (event: Event & { target: HTMLSelectElement }, id: string) => {
    const formFiltersCopy = this.formFilters;

    const editedFilterIndex = formFiltersCopy.nodes.findIndex((node) => node.id === id);

    formFiltersCopy.nodes[editedFilterIndex].type = event.target.value;
    this.formFilters = formFiltersCopy;
  };

  public addNewFilter(event: Event) {
    event.preventDefault();
    const existingFilters = this.formFilters.nodes;

    existingFilters.push({
      id: this.generateFilterId(),
      mlaasPath: '',
      target: '',
      type: 'string_eq'
    });

    this.formFilters = { ...this.formFilters, nodes: existingFilters };
  }

  public changeFilterOperator(event: Event & { target: HTMLSelectElement }) {
    this.formFilters = { ...this.formFilters, type: event.target.value };
  }

  public removeFilter(filterId: string) {
    const existingFilters = this.formFilters.nodes;
    const formFiltersWithoutRemoved = existingFilters.filter((filter) => filter.id !== filterId);
    this.formFilters = { ...this.formFilters, nodes: formFiltersWithoutRemoved };
  }

  private generateFiltersWithIds = (filter) => {
    const nodesWithId = filter.nodes.map((filter) => ({
      id: this.generateFilterId(),
      ...filter
    }));

    return {
      type: filter.type,
      nodes: nodesWithId
    };
  };

  ngOnInit() {
    this.initCommonFields();
    super.ngOnInit();
    this.accordionTabActive = 0;

    const initial = this.initialObject;
    const initialLanguages = this.creationMode ? ['en'] : initial.supportedLanguages;

    this.formFilters = initial.filter
      ? this.generateFiltersWithIds(initial.filter)
      : {
          type: 'and',
          nodes: []
        };

    this.form.controls[this.GENERATION_KEY].setValue(Generation.MOD_4);

    this.form.addControl(this.FILTER_KEY, new UntypedFormControl(this.formFilters));

    this.form.addControl(
      this.SERVICE_SUCCESS_MESSAGE,
      new UntypedFormControl(this.localization(initial.successMessages || {}, initialLanguages))
    );
    this.form.addControl(
      this.SERVICE_INFO_MESSAGE,
      new UntypedFormControl(this.localization(initial.infoMessages || {}, initialLanguages))
    );
    this.form.addControl(
      this.SERVICE_FAILURE_MESSAGE,
      new UntypedFormControl(this.localization(initial.failureMessages || {}, initialLanguages))
    );
    this.form.addControl(
      this.SERVICE_SUCCESS_AND_FAILURE_MESSAGE,
      new UntypedFormControl(this.localization(initial.successAndFailureMessages || {}, initialLanguages))
    );
    this.form.addControl(
      this.SERVICE_MISSING_EVENTS_MESSAGE,
      new UntypedFormControl(this.localization(initial.missingEventMessages || {}, initialLanguages))
    );
  }

  onSubmit = () => {
    this.submitted = true;
    const filtersWithoutId = {
      type: this.formFilters.type,
      nodes: this.formFilters.nodes.map((filter) => {
        const filterCopy = filter;
        delete filterCopy.id;
        return filterCopy;
      })
    };

    if (this.form.valid) {
      let serviceName = this.form.getRawValue().name;
      const generation = this.form.controls[this.GENERATION_KEY].value.replace(/\s/g, '');
      if (!serviceName.startsWith('MOD:')) {
        if (serviceName.match('^MOD(?:\\s\\d|[0-9])?:?\\s\\S.*')) {
          const nameAfterModId = serviceName.substring(serviceName.indexOf(':') + 1).trim();
          serviceName = generation + ': ' + nameAfterModId;
        } else {
          serviceName = generation + ': ' + serviceName;
        }
      }

      if (this.creationMode) {
        this.oncreate.emit({
          ...this.form.getRawValue(),
          name: serviceName,
          filter: filtersWithoutId
        });
      } else {
        this.onupdate.emit({
          ...this.form.getRawValue(),
          name: serviceName,
          filter: filtersWithoutId
        });
      }
    }
  };

  handleBrandChange = () => {
    this.updateValidators();
  };

  onSupportedLanguageAdded(language: string) {
    const serviceDescriptions: Localization = this.form.get(this.SERVICE_DESCRIPTION_KEY).value;
    const successMessages: Localization = this.form.get(this.SERVICE_SUCCESS_MESSAGE).value;
    const failureMessages: Localization = this.form.get(this.SERVICE_FAILURE_MESSAGE).value;
    const successAndFailureMessages: Localization = this.form.get(this.SERVICE_SUCCESS_AND_FAILURE_MESSAGE).value;
    const missingEventMessages: Localization = this.form.get(this.SERVICE_MISSING_EVENTS_MESSAGE).value;
    const infoMessages: Localization = this.form.get(this.SERVICE_INFO_MESSAGE).value;

    serviceDescriptions[language] = '';
    successMessages[language] = '';
    failureMessages[language] = '';
    successAndFailureMessages[language] = '';
    missingEventMessages[language] = '';
    infoMessages[language] = '';
  }

  onSupportedLanguageRemoved(language: string) {
    const serviceDescriptions: Localization = this.form.get(this.SERVICE_DESCRIPTION_KEY).value;
    const successMessages: Localization = this.form.get(this.SERVICE_SUCCESS_MESSAGE).value;
    const failureMessages: Localization = this.form.get(this.SERVICE_FAILURE_MESSAGE).value;
    const successAndFailureMessages: Localization = this.form.get(this.SERVICE_SUCCESS_AND_FAILURE_MESSAGE).value;
    const missingEventMessages: Localization = this.form.get(this.SERVICE_MISSING_EVENTS_MESSAGE).value;
    const infoMessages: Localization = this.form.get(this.SERVICE_INFO_MESSAGE).value;

    delete serviceDescriptions[language];
    delete successMessages[language];
    delete failureMessages[language];
    delete successAndFailureMessages[language];
    delete missingEventMessages[language];
    delete infoMessages[language];
  }

  setAccordionTabActive(tab: number) {
    if (this.accordionTabActive !== tab) {
      this.accordionTabActive = tab;
    } else {
      this.accordionTabActive = -1;
    }
  }
}
