<div *ngIf="rolesService.checkIfUserHasPermission(ADMIN_ROLE); else elseBlock">
  <div class="row row--container mar-top-large">
    <div class="col sm-12">
      <div class="card mar-top-large">
        <div class="card__content">
          <div class="row">
            <div class="col sm-6 pad-top-medium pad-bottom-medium" name="RestrictedWordList">
              <span class="h4">{{ 'RESTRICTED_WORDS.TITLE' | translate }}</span>
            </div>
            <div class="col sm-10 cursor-pointer">
              <h2 class="subtitle">{{ 'RESTRICTED_WORDS.DESCRIPTION' | translate }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row row--container">
    <div class="prepared__filters">
      <div class="card__content">
        <div class="row">

          <div *ngFor="let brand of rolesService.getBrandsWhereMaster()">
            <div (click)="handleBrandChange(brand)" id="{{brand}}-tab">
              <groupui-tabs>
                <groupui-tab tab={{brand}} name={{brand}}>
                </groupui-tab>
              </groupui-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col sm-12">
      <div class="card">
        <div class="card__content" style="margin-top: -50px;">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ 'RESTRICTED_WORDS.CURRENT_LIST_TEXT' | translate }}
                  {{ this.initialBrand }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of initialWords">
                <td>{{ item.word }}</td>

                <td class="button-wrapper">
                  <button class="btn btn--small bg-danger" name="delete-restricted-word-button" type="submit"
                    id="delete-word-{{ item.word }}" (click)="deleteRestrictedWord(item)">
                    {{ 'RESTRICTED_WORDS.DELETE' | translate }}
                  </button>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row row--container">
    <div class="col sm-12">
      <div class="card mar-top-large">
        <div class="card__content">
          <h1 class="h5">{{ 'RESTRICTED_WORDS.ADD_WORDS_SECTION' | translate }}</h1>
          <form #RestrictedWordsForm="ngForm" (ngSubmit)="this.addNewRestrictedWord(RestrictedWordsForm)">
            <div class="row pad-top-medium">
              <div class="col sm-8">
                <input placeholder="{{ 'RESTRICTED_WORDS.PLACEHOLDER_ADD_NEW_WORD' | translate }}" name="word" id="word"
                  class="" type="text" required ngModel />
              </div>
              <div>
                <button class="btn btn--primary" name="addWord" id="addWord" type="submit">
                  <span class="icon i-save"></span>
                </button>
              </div>
              <div class="pad-left-large">
                <div class="btn btn--fab" id="{{ initialBrand }}-button">
                  <img alt="{{ initialBrand }}" class="taskbar__picture" src="/assets/images/brand-logos/{{ initialBrand }}.png" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #elseBlock>
  <app-unauthorized-access></app-unauthorized-access>
</ng-template>
