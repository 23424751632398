export enum Role {
  ADMIN = 'ADMIN',
  MASTER = 'MASTER',
  BASIC = 'BASIC'
}

export class Constants {
  private static configServiceBaseURL = '/api/config';
  private static excelServiceBaseUrl = '/api/excel';

  public static SERVICES_URL = `${Constants.configServiceBaseURL}/services`;
  public static CUSTOMER_SERVICES_URL = `${Constants.configServiceBaseURL}/customer-services`;
  public static CUSTOMIZED_SERVICES_URL = `${Constants.configServiceBaseURL}/customized-services`;
  public static SERVICE_BY_BRAND_URL = `${Constants.configServiceBaseURL}/brands`;
  public static WHITELIST_SERVICE_URL = `${Constants.configServiceBaseURL}/whitelist`;

  public static NEWSLETTERS_URL = `${Constants.configServiceBaseURL}/admin/newsletters`;
  public static HISTORY_URL = `${Constants.configServiceBaseURL}/admin/history`;
  public static DEFAULT_RESPONSES_URL = `${Constants.configServiceBaseURL}/default-responses`;

  public static EXCEL_SERVICE_URL = `${Constants.excelServiceBaseUrl}/brands`;
  public static ALL_ROLES: Role[] = Object.keys(Role).map((role) => Role[role]);

  public static serviceUri(brand: string, modId: string) {
    return `${Constants.SERVICE_BY_BRAND_URL}/${brand}/services/${modId}`;
  }

  public static excelServiceUri(brand: string, modId: string) {
    return `${Constants.EXCEL_SERVICE_URL}/${brand}/services/${modId}`;
  }

  public static restrictedWordsUri(brand: string) {
    return `${Constants.SERVICE_BY_BRAND_URL}/${brand}/restricted-words`;
  }
}
